<!-- 知识点 -->
<style lang="scss" scoped>
.key_point{
    padding: 20px 0;
    height: 100%;
    overflow: auto;
    .course_brief{
        background: #FFFFFF;
		border-radius: 14px;
		padding: 32px;
        .course{
            line-height: 30px;
            display: flex;
            .el-tag{
                float: left;
                margin-right: 20px;
            }
        }
        h4{
            font-size: 18px;
            font-weight: bold;
        }
        .userInfo{
            margin-top: 20px;
            .img{
				width: 72px;
				height: 72px;
				border-radius: 50%;
				margin-right: 16px;
            }
            .teacherName{
                font-size: 20px;
				font-weight: 400;
				color: #444444;
				line-height: 28px;
            }
            .teacherTitle{
				margin-top: 6px;
				height: 28px;
				font-size: 16px;
				font-weight: 400;
				color: #888888;
				line-height: 28px;
            }
        }
    }
    .pointlist{
        width: 100%;
        margin-bottom: 30px;
        .li{
            box-sizing: border-box;
            width: 100%;
            background: #FFFFFF;
            border-radius: 14px;
            padding: 20px;
            margin-top: 20px;
            .number{
                min-width: 28px;
                padding: 0 8px;
                height: 28px;
                line-height: 28px;
                background: rgba(1, 161, 253, 0.1);
                border-radius: 100px;
                font-size: 18px;
                color: #01A1FD;
                margin-right: 16px;
                box-sizing: border-box;
            }
            .name{
                font-size: 18px;
                font-weight: bold;
                color: #222222;
                line-height: 40px;
            }
            .p{
                font-size: 16px;
                font-weight: 400;
                color: #444444;
                line-height: 30px;
            }
        }
    }
}
</style>

<template>
    <div class="key_point">
        <div class="course_brief">
            <div class="course" v-if="knowledgeInfo.sysub_name">
                <el-tag>{{ knowledgeInfo.sysub_name || '-' }}</el-tag>
                <h4 class="flex_1">{{ knowledgeInfo.sccou_cha_title || '-' }}</h4>
            </div>
            <div class="userInfo flex_align">
                <img class="img" :src="knowledgeInfo.teuse_image?knowledgeInfo.teuse_image:require('@assets/images/empty_avatar.png')" alt="">
                <div>
					<div class="teacherName">{{knowledgeInfo.teuse_name}}</div>
					<div class="teacherTitle">授课老师</div>
				</div>
            </div>
        </div>
        <div class="pointlist">
            <div class="li" v-for="(item,index) in knowledgeInfo.knowledge" :key="index">
                <div class="flex_content_center">
                    <div class="number flex_content_center">{{index+1}}</div>
                    <div class="name flex_1">{{item.teles_kno_title}}</div>
                </div>
                <div class="p">{{item.teles_kno_content}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { get_Knowledge } from "@api/home"
export default {
    name: 'keyPoint',
    data() {
        return {
            knowledgeInfo:{
                sysub_name:"",
                tecla_id: 0,
                teuse_name: "",
                teuse_image: "",
                tecla_grade: 0,
                sccla_name: "",
                sccou_cha_title: "",
                sysub_name: "",
                tecla_grade_name: "",
                knowledge: []
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            let { data } = await get_Knowledge({tecla_id:this.$route.params.id});
            this.knowledgeInfo = data;
            this.$forceUpdate();
        },
      
    },
}
</script>